import '../styles/globals.css';
import { appWithTranslation } from 'next-i18next';
import nextI18nConfig from '../next-i18next.config';
import { GoogleReCaptchaProvider } from '../components/recaptcha/google-recaptcha-provider';
import AzureAppInsights from '../components/general/AzureAppInsights';
import { wrapper } from '../store/index';
import { Provider } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const App = ({ Component, ...rest }) => {
  const { store, props } = wrapper.useWrappedStore(rest);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      const theme = props.pageProps?.metadata?.theme;
      if (theme) {
        document.body.className = theme;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Apply theme on initial load
    handleRouteChange();

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, props.pageProps?.metadata?.theme]);

  return (
    <AzureAppInsights>
      <Provider store={store}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: true, // optional, default to false,
            defer: true, // optional, default to false
            appendTo: 'body', // optional, default to "head", can be "head" or "body",
            nonce: undefined,
          }}
        >
          <Component {...props.pageProps} />
        </GoogleReCaptchaProvider>
      </Provider>
    </AzureAppInsights>
  );
};

// https://github.com/i18next/next-i18next#unserializable-configs
export default appWithTranslation(App, nextI18nConfig);
